import { Link } from "gatsby";
import { Link as ILink, useI18next } from 'gatsby-plugin-react-i18next';
import { Squash as Hamburger } from "hamburger-react";
import * as React from "react";
import { useState } from "react";
import { animated, useSpring } from 'react-spring';
import { useMobile } from "../hooks/hooks.js";
import logowhite from "../images/logowhite.png";
import smallBubble from "../images/smallbubble.png";
import { WidthContainer } from "./WidthContainer";
import {useTranslation} from "react-i18next";

const HeaderLink = ({
    label,
    active,
    style,
    to
}: {
    label: string,
    active?: boolean,
    style?: any,
    to: string
}) => {
    const { transform } = useSpring({
        from: { transform: "rotate(0deg)" },
        to: { transform: "rotate(360deg)" },
        loop: true,
        config: { duration: 20000 }
    })
    const [hover, hoverSet] = useState(false)

    return (
        <Link
            to={to}
            style={{
                display: "grid",
                gridTemplate: `
            ""
        `,
            }}
            {...{
                onMouseEnter: () => {
                    hoverSet(true)
                },
                onMouseLeave: () => {
                    hoverSet(false)
                }
            }}
        >
            <div id=""
                {...{
                    style: {
                        fontSize: "20px",
                        margin: "0 16px",
                        textShadow: active || hover ?
                            "0px 0px 1px #fff, 0px 0px 10px #fff" :
                            "",
                        backdropFilter: (() => {
                            if (active) return "blur(4px)"
                            if (hover) return "blur(4px)"
                            return ""
                        })(),
                        background: (() => {
                            if (active) return "#fff3"
                            if (hover) return "#fff3"
                            return ""
                        })(),
                        borderRadius: "99px",
                        display: "grid",
                        gridTemplateColumns: active ? "24px auto" : "auto",
                        placeItems: "center",
                        fontWeight: active || hover ? "400" : "300",
                        padding: "0 12px",
                        ...style
                    },
                }}>
                {active &&
                    <animated.img src={smallBubble} alt="" style={{
                        height: "1.8vh", width: "1.8vh",
                        marginRight: "8px",
                        transform
                    }} />
                }
                {!active && <div />}
                <div id=""
                    {...{
                        style: {
                            paddingRight: "4px"
                        },
                    }}>
                    {label}
                </div>
            </div>
        </Link >
    )
}

const Logo = ({ }: any) => {
    const { medium } = useMobile()

    return (
        <div
            id="Logo"
            className=""
            {...{
                style: {
                    placeSelf: "center start"
                },
            }}
        >
            <img src={logowhite} alt="" style={{
                // height: "5vh",
                width: "180px",
                marginTop: medium ? "" : ""
            }} />
        </div>
    )
}

const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

const Header = ({ location }: any) => {

    const inMenu = typeof location === "string";
    const { medium } = useMobile()

    const languageLabels = {
        en: "English",
        fr: "Français",
        // Add more languages and labels as needed
    };

    const [navMenuIsOpen, navMenuIsOpenSet] = React.useState(false)
    const { background } = useSpring({
        //1A2E4E
        from: { background: navMenuIsOpen ? "#1A2E4E00" : "1A2E4EC00" },
        to: { background: navMenuIsOpen ? "#1A2E4Eff" : "#1A2E4E00" },
    })
    const { gridTemplateRows } = useSpring({
        from: { gridTemplateRows: navMenuIsOpen ? "0fr" : "0fr" },
        to: { gridTemplateRows: navMenuIsOpen ? "1fr" : "0fr" },
        config: { precision: 0.00001 }
    })

    const [scrollWindowY, scrollWindowYSet] = useState(0)
    // console.log({ scrollWindowY });


    React.useEffect(() => {
        window.addEventListener("scroll",
            () => scrollWindowYSet(window.scrollY)
        )
    })
    const { languages, originalPath, t, i18n, language, changeLanguage} = useI18next();

    // Function to handle language change
    const handleLanguageChange = (event) => {
        event.preventDefault();
        changeLanguage(event.target.value);
    };

    const toggleLanguage = (event) => {
        event.preventDefault();
        changeLanguage(event.target.checked ? 'fr' : 'en');
    };

    return (
        <>
            <animated.div
                id="Header"
                {...{
                    style: {
                        display: "grid",
                        position: "fixed",
                        top: 0,
                        zIndex: 9999999,
                        background,
                        color: "white",
                        width: "100%"
                    },
                }}
            >
                <div id=""
                    className=""
                    {...{
                        style: {
                            background: `rgba(26,46,78,${clamp(scrollWindowY / 250, 0, 1)})`
                        },
                    }}>

                    <WidthContainer center>
                        <div id=""
                            className=""
                            {...{
                                style: {
                                    display: "grid",
                                    gridTemplate: medium ? `
                                        "a b c"
                                    ` : `
                                        "a b c d e f g"
                                        / auto 1fr auto auto auto auto auto
                                    `,
                                    height: "96px",
                                },
                            }}>
                            <Link to="/" style={{
                                display: "grid",
                                gridTemplate: `
                                ""
                            `,
                            }}>
                                <Logo />
                            </Link>
                            <div></div>
                            {!medium &&
                                <>
                                    <HeaderLink {...{
                                        active: location === `/` || location === `/${language}/`,
                                        label: `${t('header.home')}`,
                                        style: { placeSelf: "center" },
                                        to: "/"
                                    }} />
                                    <HeaderLink {...{
                                        active: inMenu && location.includes(`/offre-ambassadeurs`),
                                        label: `${t('header.ambassadorOffer')}`,
                                        style: { placeSelf: "center" },
                                        to: "/offre-ambassadeurs"
                                    }} />
                                    <HeaderLink {...{
                                        active: inMenu && location.includes("/offre-entreprises"),
                                        label: `${t('header.businessOffer')}`,
                                        style: { placeSelf: "center" },
                                        to: "/offre-entreprises"
                                    }} />
                                    <HeaderLink {...{
                                        active: inMenu && location.includes("/contact"),
                                        label: `${t('header.contact')}`,
                                        style: { placeSelf: "center" },
                                        to: "/contact"
                                    }} />
                                    <select className="language-switcher" onChange={handleLanguageChange} value={language}>
                                        {languages.map((lng) => (
                                            <option value={lng} key={lng}>{languageLabels[lng]}</option>
                                        ))}
                                    </select>
                                </>
                            }

                            {medium && (
                                <div className="header-menu">
                                    <div className="switch-container">
                                        <div className="switch">
                                            <input id="language-toggle" className="check-toggle check-toggle-round-flat"
                                                   type="checkbox" onChange={toggleLanguage} checked={language === 'fr'}/>
                                                <label htmlFor="language-toggle"></label>
                                                <span className="off">Fr</span>
                                                <span className="on">En</span>
                                        </div>
                                    </div>
                                    <div id=""
                                        className=""
                                        {...{
                                            style: {
                                                placeSelf: "center end"
                                            },
                                        }}>
                                        <Hamburger
                                            toggled={navMenuIsOpen}
                                            toggle={() => navMenuIsOpenSet(!navMenuIsOpen)}
                                            rounded
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </WidthContainer>
                </div>

                <animated.div id=""
                    className=""
                    {...{
                        style: {
                            overflow: "hidden",
                            display: "grid",
                            gridTemplate: `
                                    "a"
                                `,
                            // background,
                            gridTemplateRows,
                        },
                    }}
                >
                    <div id=""
                        className=""
                        {...{
                            style: {
                                gridArea: "a",
                                overflow: "hidden",
                            },
                        }}>
                        <WidthContainer center>
                            <div id=""
                                className=""
                                {...{
                                    style: {
                                        display: "grid",
                                        // gridTemplateRows: "repeat(4, 52px)",
                                        rowGap: "32px"
                                    },
                                }}>


                                <HeaderLink {...{
                                    active: location === `/` || location === `/${language}/`,
                                    label: `${t('header.home')}`,
                                    style: { placeSelf: "start" },
                                    to: "/"
                                }} />
                                <HeaderLink {...{
                                    active: inMenu && location.includes("/offre-ambassadeurs"),
                                    label: `${t('header.ambassadorOffer')}`,
                                    style: { placeSelf: "start" },
                                    to: "/offre-ambassadeurs"
                                }} />
                                <HeaderLink {...{
                                    active: inMenu && location.includes("/offre-entreprises"),
                                    label: `${t('header.businessOffer')}`,
                                    style: { placeSelf: "start" },
                                    to: "/offre-entreprises"
                                }} />
                                <HeaderLink {...{
                                    active: inMenu && location.includes("/contact"),
                                    label: `${t('header.contact')}`,
                                    style: { placeSelf: "start" },
                                    to: "/contact"
                                }} />
                            </div>
                            <div id=""
                                className=""
                                {...{
                                    style: {
                                        paddingBottom: "32px"

                                    },
                                }}>

                            </div>
                        </WidthContainer>
                    </div>
                </animated.div>
            </animated.div>

        </>
    )
}

export { Header };
