import * as React from "react";
import "../styles/tailwind-fonts.css";
import "../styles/styles.css";
import { Helmet } from "react-helmet"
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ogImg from "../images/Happiz-application-hypnose.jpeg"
import CookieConsent, {Cookies} from "react-cookie-consent";
import {useTranslation} from "gatsby-plugin-react-i18next";

const AppWrapper = ({ children }) => {

    const { t } = useTranslation()
    const setTrackingCookies = (isActive) => {
        Cookies.set("gatsby-gdpr-google-tagmanager", isActive)
        Cookies.set("gatsby-gdpr-facebook-pixel", isActive)
        Cookies.set("gatsby-gdpr-tiktok-pixel", isActive)
    }

    return (
        <div
            className=""
            {...{
                id: "AppWrapper",
                style: {
                    fontFamily: "Montserrat",
                    height: "100%",
                    // display: "grid"
                },
            }}
        >
            <ToastContainer
                position="bottom-center"
                autoClose={4000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                transition={Slide}
                theme="colored"
            />
            <Helmet>
                <meta charSet="utf-8" />
                <meta name="description" content="Happiz vous propose des séances d’hypnose thérapeutique pour vous aider dans l’amélioration de votre sommeil, l’apaisement de votre anxiété / stress / déprime, sevrage tabagique." />
                <title>{t('cookies.title')}</title>
                {/* <script dangerouslySetInnerHTML={{__html:`
                !function (w, d, t) {
                    w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
          )ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};

                    ttq.load('CAL0IU3C77U7SS4AHR9G');
                    ttq.page();
                  }(window, document, 'ttq');
                `}} /> */}
                <meta property="og:title" content="Happiz, l'hypnose pour votre bien-être"/>
                <meta property="og:description" content="Stress, anxiété, déprime, addiction ou insomnie ? Découvrez l'application Happiz"/>
                <meta property="og:image" content={ogImg} />
                <meta property="og:url" content="https://behappiz.com" />
            </Helmet>

            <CookieConsent
                enableDeclineButton
                location="bottom"
                buttonText={t('cookies.accept')}
                declineButtonText={t('cookies.decline')}
                cookieName="CookieConsent"
                onAccept={()=>{setTrackingCookies(true)}}
                onDecline={()=>{setTrackingCookies(true)}}
                style={{ zIndex: "100", background:"rgb(16, 37, 67)" }}
                buttonStyle={{background:"#4a7fc8",color:"white",borderRadius:"10px"}}
                declineButtonStyle={{background:"#284771",color:"white",borderRadius:"10px"}}
                flipButtons
            >
                {t('cookies.privacy')}<br/>
                {t('cookies.usage')}
            </CookieConsent>

            {children}
        </div>
    )
}

export { AppWrapper };
