import { useEffect, useState } from "react";


function useMobile() {
    // console.log("-> function useMobile()");
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = useState({
        width: 400,
    });
    useEffect(() => {
        // Handler to call on window resize
        function handleResize() {
            // Set window width/height to state
            setWindowSize({
                width: window.innerWidth,
            });
        }
        // Add event listener
        window.addEventListener("resize", handleResize);
        // Call handler right away so state gets updated with initial window size
        handleResize();
        // Remove event listener on cleanup
        return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount

    const mobile = windowSize.width <= 600
    const tablet = windowSize.width <= 768
    const medium = windowSize.width <= 1024

    // console.log(windowSize.width, "windowSize.width");

    return {
        mobile,
        medium,
        tablet,
    };
}

const UseContainerWidth = () => {
    const { mobile, tablet } = useMobile()
    if (mobile) return "clamp(0px, 90vw, 1640px)"
    if (tablet) return "clamp(0px, 90vw, 1640px)"
    return "clamp(0px, 90vw, 1960px)"
}

export { useMobile };
export { UseContainerWidth };
