import React from "react";
import { Link } from "gatsby";
import {useI18next, useTranslation} from "gatsby-plugin-react-i18next"; // Make sure to import useTranslation
import { ButtonStore } from "./ButtonStore";

const Footer = () => {
    const { t, language} = useI18next();

    return (
        <footer className="bg-ink">
            <div className="container mx-auto px-4 md:px-0 grid grid-cols-1 md:grid-cols-3 gap-8 pb-8">
                <div>
                    <img src="/images/logo_cropped.png" alt={t("footer.logoAlt")} className="w-64 h-auto mb-6"/>
                    <p className={`text-sm font-light text-white mb-6 tagline-style ${language}`} >{t("footer.logoTag")}</p>
                    <p className="text-sm font-light text-white">{t("footer.description")}</p>
                    <p className="text-sm font-light text-white">{t("footer.tagline")}</p>
                </div>
                <div className="flex flex-col gap-4 text-white">
                    <Link to="/contact">{t("footer.contactUs")}</Link>
                    <Link to="/politique-de-confidentialite">{t("footer.privacyPolicy")}</Link>
                    <Link to="/conditions-generales-utilisation">{t("footer.termsOfUse")}</Link>
                    <Link to="/offre-entreprises">{t("footer.offerForBusinesses")}</Link>
                    <Link to="/offre-ambassadeurs">{t("footer.offerForAmbassadors")}</Link>
                    <Link to="/se-former-hypnose">{t("footer.learnHypnosis")}</Link>
                </div>
                <div className="flex gap-8 flex-col items-center">
                    <ButtonStore variant="apple" fullWidth/>
                    <ButtonStore variant="playstore" fullWidth/>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
