import * as React from "react";
import { ReactNode } from "react";
import { useMobile } from "../hooks/hooks.js";


const WidthContainerStrict = ({
    children,
    style
}) => {
    const { mobile, tablet } = useMobile()

    const getWidth = () => {
        // if (mobile) return "clamp(0px, 90vw, 1640px)"
        // if (tablet) return "clamp(0px, 86vw, 1640px)"
        return "clamp(0px, 86vw, 1960px)"
    }
    return (
        <div
            {...{
                id: "WidthContainerStrict",
                style: {
                    width: getWidth(),
                    ...style
                },
            }}
        >
            {children}
        </div>
    )
}

const WidthContainer = (
    {
        children,
        center,
        style
    }: {
        children: ReactNode,
        center?: boolean,
        style?: any
    }) => {

    if (center) return (
        <div id="WidthContainer"
            className=""
            {...{
                style: {
                    width: "100%",
                    display: "grid",
                    placeItems: "center",
                },
            }
            }
        >
            <WidthContainerStrict {...{ style }}>
                {children}
            </WidthContainerStrict>
        </div>
    )

    return (
        <WidthContainerStrict {...{ style }}>
            {children}
        </WidthContainerStrict>
    )
}

export { WidthContainer };
