export const ANALYTIC_EVENT_NAMES = {
  web_page_viewed: 'web_page_viewed',
  web_404_page_viewed: 'web_404_page_viewed',
  web_service_unavailable_page_viewed: 'web_service_unavailable_page_viewed',
  web_general_terms_and_conditions_page_viewed: 'web_general_terms_and_conditions_page_viewed',
  web_contact_page_viewed: 'web_contact_page_viewed',
  web_email_page_viewed: 'web_email_page_viewed',
  web_home_page_viewed: 'web_home_page_viewed',
  web_ambassador_offer_page_viewed: 'web_ambassador_offer_page_viewed',
  web_company_offer_page_viewed: 'web_company_offer_page_viewed',
  web_privacy_policy_page_viewed: 'web_privacy_policy_page_viewed',
  web_practitioners_and_hypnosis_page_viewed: 'web_practitioners_and_hypnosis_page_viewed',
  web_hypnosis_training_page_viewed: 'web_hypnosis_training_page_viewed',
  web_company_form_sent: 'web_company_form_sent',
  web_company_form_unsent: 'web_company_form_unsent',
  web_contact_form_sent: 'web_contact_form_sent',
  web_contact_form_unsent: 'web_contact_form_unsent',
  web_ambassador_subscription_sent: 'web_ambassador_subscription_sent',
}
